import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PricingPlan from "./PricingPlan";
import SupportSection from "components/Layouts/Support/SupportSection";
import FAQ from "components/Layouts/Faq/Faq";
import pricingPlans from "data/pricingData";
import { faqPricingData } from "data/faqData";
import ComparePlans from "./ComparePlans.";
import { TargetIntegrationSection } from "components/Layouts/Integrations";

const PricingTable: React.FC = () => {
  const { starter, pro, premium } = pricingPlans;
  const plans = [starter, pro, premium];

  return (
    <>
      <Helmet>
        <title>Plans & Pricing - SKULabs</title>
        <meta
          name="description"
          content="Compare SKULabs pricing plans and features to choose the best fit for your business."
        />
      </Helmet>

      <main>
        <div className="pricing-page container">
          <div className="pricing-head">
            <h1>Grow your business with SKULabs</h1>
            <ul className="custom-list other">
              <li>No contract or onboarding fees</li>
              <li>30 day money back guarantee</li>
              <li>Flexible Month to Month</li>
            </ul>
          </div>
          <div className="plans">
            {plans.map((plan) => (
              <PricingPlan key={plan.name} plan={plan} />
            ))}
          </div>
          <div className="plan-enterprise">
            <img
              src="/icons/enterprise.svg"
              alt="warehouse"
              width={216}
              height={216}
              loading="lazy"
            />
            <div className="plan-enterprise-info">
              <p className="title">Custom Plan</p>
              <p>
                We offer support for more sales channels and more shipping
                providers than any other e-commerce solution. Connect all of
                your stores and shipping accounts to one easy-to-use dashboard,
                and manage all of your orders and shipments in one place
              </p>
              <div className="ctas">
                <Link to="/talk" className="cta">
                  Request a Quote
                </Link>
                <a href="tel:+18002432986" className="call-link">
                  Call us today:{" "}
                  <span className="accent">+1 (800) 243-2986</span>
                </a>
              </div>
            </div>
          </div>
          <ComparePlans />
        </div>
        <div className="pricing-faq">
          <FAQ items={faqPricingData} />
        </div>
        <TargetIntegrationSection />
        <SupportSection />
      </main>
    </>
  );
};

export default React.memo(PricingTable);
