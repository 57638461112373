import { Link } from "react-router-dom";
import NavMenu from "components/Layouts/Header/NavMenu/NavMenu";
import { ReactComponent as SkulabsLogo } from "assets/logos/skulabs-logo.svg";

interface HeaderProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <div className="container">
        <Link to="/" className="logo" onClick={closeMenu}>
          <SkulabsLogo />
        </Link>
        <NavMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <div className="ctas">
          <a href="tel: +18002432986" className="cta-link">
            (800) 243-2986
          </a>
          <Link to="/pricing" className="cta">
            Get Started Today
          </Link>
          <Link
            to="https://app.skulabs.com/login"
            className="cta-border small"
            target="_blank"
            rel="noopener"
          >
            Log In
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
