import { useState } from "react";
import ReactPlayer from "react-player";
import previewVideoDefault from "assets/images/preview-video-default.webp";
import previewVideoDefaultMobile from "assets/images/preview-video-default-mobile.webp";
import playIcon from "assets/icons/play-button.svg";

interface VideoSectionProps {
  video?: string;
  previewVideoDesc?: string;
  previewVideoMob?: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({
  video,
  previewVideoDesc,
  previewVideoMob,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  console.log(previewVideoDesc, previewVideoMob);

  return (
    <>
      <div className="video-wrapper">
        <picture>
          <source
            srcSet={
              previewVideoMob || previewVideoDesc || previewVideoDefaultMobile
            }
            media="(max-width: 640px)"
            width={400}
            height={210}
          />
          <img
            src={previewVideoDesc || previewVideoDefault}
            alt="SKULabs video preview"
            className="preview"
            loading="lazy"
            width={1274}
            height={740}
            onClick={handlePlayClick}
          />
        </picture>

        {!isPlaying && (
          <div className="play-button" onClick={handlePlayClick}>
            <img src={playIcon} alt="Play icon" width={32} height={32} />
          </div>
        )}
        <div
          className="player-screen-size"
          style={{ zIndex: isPlaying ? "1" : "-1" }}
        >
          {isPlaying && (
            <ReactPlayer
              playing={isPlaying}
              url={video}
              controls
              width="100%"
              height="100%"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoSection;
