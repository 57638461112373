import IntegrationCardsList from "components/Layouts/Integrations/IntegrationsCards/IntegrationCardsList";
import SupportSection from "components/Layouts/Support/SupportSection";
import { Helmet } from "react-helmet";

const Integrations: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Connect SKULabs to Amazon, eBay, Shopify, Etsy, and more</title>
        <meta
          name="description"
          content="Connect and Manage All Your Stores Effortlessly"
        />
      </Helmet>
      <main className="integration">
        <div className="container">
          <h1>Connected Partners</h1>
          <IntegrationCardsList />

          <p>
            We offer support for more sales channels and more shipping providers
            than any other e-commerce solution. Connect all of your stores and
            shipping accounts to one easy-to-use dashboard, and manage all of
            your orders and shipments in one place. If we somehow don't support
            a service you use, let us know so we can get to work on it.
          </p>
        </div>
      </main>
      <SupportSection />
    </>
  );
};

export default Integrations;
