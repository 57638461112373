import paths from "helpers/paths";

export interface MenuItem {
  title: string;
  url?: string;
  external?: boolean;
  className?: string;
}

export interface MenuSection {
  title: string;
  items: MenuItem[];
}

export const footerMenuData: MenuSection[] = [
  {
    title: "Features",
    items: [
      { title: "Order picking", url: paths.orderPicking },
      { title: "Multi-carrier shipping", url: paths.shipping },
      { title: "Inventory management", url: paths.inventoryManagement },
      { title: "Order Management", url: paths.orderManagement },
      { title: "Purchase Orders", url: paths.purchaseOrder },
      { title: "Warehouse management", url: paths.warehouseManagement },
      { title: "Catalog management", url: paths.catalogManagement },
      { title: "Order management", url: paths.orderManagement },
      { title: "Batch picking", url: paths.batchPicking },
    ],
  },
  {
    title: "Resources",
    items: [
      { title: "Price", url: paths.pricing },
      { title: "About us", url: paths.about },
      { title: "Careers", url: paths.careers, external: true },
      { title: "Reviews", url: paths.reviews },
      { title: "Hardware", url: paths.hardware },
      { title: "Academy", url: paths.academyList },
      { title: "FAQ", url: paths.faq },
      { title: "API", url: paths.api, external: true },
      { title: "Blog", url: paths.blog, external: true },
      {
        title: "Help Center",
        url: paths.supportPro,
        external: true,
      },
    ],
  },
  {
    title: "Partners",
    items: [
      { title: "Amazon", url: "/integrations/amazon" },
      { title: "Shopify", url: "/integrations/shopify" },
      { title: "Ebay", url: "/integrations/ebay" },
      { title: "Bigcommerce", url: "/integrations/walmart" },
      { title: "Walmart", url: "/integrations/walmart" },
      { title: "USPS", url: "/integrations/usps" },
      { title: "UPS", url: "/integrations/ups" },
      { title: "FedEx", url: "/integrations/fedex" },
      { title: "All Our Partners", url: paths.integrations },
    ],
  },
  {
    title: "Case Studies",
    items: [
      { title: "House of Tinks", url: paths.caseStudies.hot },
      { title: "Fashion ", url: paths.industriesLinks.fashion },
      { title: "3PL ", url: paths.industriesLinks.threepl },
    ],
  },
];
