import {
  createContext,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
} from "react";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import paths from "helpers/paths";
import isChatOnline from "helpers/dateUtils";
import SkulabsDotCom from "SkulabsDotCom";
import { ErrorBoundary, getGlobalObject } from "helpers/DataDog";
import integrationsList from "data/integrationData";
import {
  legacyRedirectsData,
  externalRedirectsData,
  ExternalRedirect,
  handleSpecialRedirects,
  handleRedirects,
} from "helpers/redirectsConfig";
import Features from "Pages/Features/Features";
import Integrations from "Pages/Integrations/Integrations";

import Industries from "Pages/Industries/Industries";
import { datadogRum } from "@datadog/browser-rum";
import FaqPage from "Pages/FAQ/FAQPage";

import Contacts from "Pages/Contact/Contact";
import PricingRoute from "Pages/Pricing/PricingRoute";
import Talk from "Pages/Demo/Talk";
import Homepage from "Pages/Homepage/Homepage";
import HoT from "Pages/CaseStudies/HoT";
import PricingTable from "Pages/Pricing/PricingTable";

datadogRum.init({
  applicationId: "d1aa778f-cf52-471e-a273-72fccd0a05e7",
  clientToken: "pub78319df2f8b8923f1d1751ab6c03e259",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "skulabs-www",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "1.0.73839",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  defaultPrivacyLevel: "mask-user-input",
});

const Demo = lazy(() => import("Pages/Demo/Demo"));
const Call = lazy(() => import("Pages/Demo/Call"));
const ShippingTemplate = lazy(() => import("Pages/ShippingTemplate"));
const InventoryTemplate = lazy(() => import("Pages/InventoryTemplate"));

const Academy = lazy(() => import("Pages/Academy/Academy"));
const AcademyBenefits = lazy(() => import("Pages/Academy/AcademyBenefits"));
const AcademyCycleCounts = lazy(
  () => import("Pages/Academy/AcademyCycleCounts")
);
const AcademyInventoryForecasting = lazy(
  () => import("Pages/Academy/AcademyInventoryForecasting")
);
const AcademyInventoryManagement = lazy(
  () => import("Pages/Academy/AcademyInventoryManagement")
);
const AcademyMultichannelInventory = lazy(
  () => import("Pages/Academy/AcademyMultichannelInventory")
);
const AcademySerializedInventory = lazy(
  () => import("Pages/Academy/AcademySerializedInventory")
);
const Ai = lazy(() => import("Pages/Ai/Ai"));
const Ccpa = lazy(() => import("Pages/Policy/Ccpa"));
const ChangeLog = lazy(() => import("Pages/ChangeLog/ChangeLog"));
const Connect = lazy(() => import("Pages/Connect"));
const Gdpr = lazy(() => import("Pages/Policy/Gdpr"));
const Hardware = lazy(() => import("Pages/Hardware/Hardware"));
const Health411 = lazy(() => import("Pages/Health411"));
const Loading = lazy(() => import("Pages/Loading"));
const InventoryLanding = lazy(
  () => import("Pages/InventoryLanding/InventoryLanding")
);
const InventoryPricing = lazy(
  () => import("Pages/InventoryLanding/InventoryPricing")
);
const NotFound = lazy(() => import("Pages/NotFound"));
const PrivacyPolicy = lazy(() => import("Pages/Policy/PrivacyPolicy"));
const Process = lazy(() => import("Pages/Process"));
const Redirecting = lazy(() => import("Pages/Redirecting"));
const Reviews = lazy(() => import("Pages/Reviews/Reviews"));
const Sitemap = lazy(() => import("Pages/Sitemap/Sitemap"));
const StitchlabAlternative = lazy(() => import("Pages/StitchlabAlternative"));
const TermsOfUse = lazy(() => import("Pages/Policy/TermsOfUse"));
const About = lazy(() => import("Pages/About/About"));
const Landing = lazy(() => import("Pages/Landing/Landing"));
const SingleIntegration = lazy(
  () => import("Pages/Integrations/SingleIntegration")
);

interface ISkulabsContextProps {
  showFooterCta: boolean;
  updateShowFooterCta: React.Dispatch<React.SetStateAction<boolean>>;
  chatOnline: boolean;
  updateChatOnline: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SkulabsContext = createContext<ISkulabsContextProps>(null!);

const scrollToHash = () => {
  const hash = window.location.hash;
  if (hash) {
    setTimeout(() => {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }
};

const SkulabsRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;

  const [showFooterCta, updateShowFooterCta] = useState<boolean>(true);
  const [chatOnline, updateChatOnline] = useState<boolean>(true);

  useEffect(() => {
    scrollToHash();
  }, [location.pathname]);

  useEffect(() => {
    const checkChatOnline = () => updateChatOnline(isChatOnline());
    checkChatOnline();
    const intervalId = setInterval(checkChatOnline, 600000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const shouldMonitor = () => {
      const params = new URLSearchParams(search);
      return params.get("DD_RUM") !== "false";
    };

    ReactGA.send({ hitType: "pageview", page: pathname });
    document.documentElement.scrollTo(0, 0);

    const globalObj = getGlobalObject<Window>();
    if (!globalObj.DD_RUM) {
      console.warn(
        "@datadog/rum-react-integration: Datadog RUM SDK is not initialized."
      );
      return;
    }
    if (!globalObj.DD_RUM?.startView) {
      console.warn(
        "@datadog/rum-react-integration: Manual tracking not supported. Try updating the Datadog RUM SDK."
      );
      return;
    }
    if (!globalObj.DD_RUM?.getInitConfiguration()?.trackViewsManually) {
      console.warn(
        "@datadog/rum-react-integration: The trackViewsManually flag in RUM initialization must be set to %ctrue%c.",
        "color:green",
        "color:default"
      );
      return;
    }

    if (shouldMonitor()) {
      globalObj.DD_RUM.startView(pathname);
    }
  }, [pathname, search]);

  const starterContext = useMemo(
    () => ({
      showFooterCta,
      updateShowFooterCta,
      chatOnline,
      updateChatOnline,
    }),
    [showFooterCta, chatOnline]
  );

  const specialRedirect = handleSpecialRedirects(pathname);
  if (specialRedirect) {
    return specialRedirect;
  }

  handleRedirects(pathname, navigate);

  return (
    <SkulabsContext.Provider value={starterContext}>
      <ErrorBoundary
        fallback={(error) => (
          <div>An error was encountered {error.message}</div>
        )}
      >
        <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
          {/* <Routes>
            <Route
              path="/shipping-label-template"
              element={<ShippingTemplate />}
            />
            <Route path="/inventory-template" element={<InventoryTemplate />} />
          </Routes> */}

          {!["/shipping-label-template", "/inventory-template"].includes(
            location.pathname
          ) && (
            <SkulabsDotCom>
              <ErrorBoundary
                fallback={(error) => (
                  <div>An error was encountered {error.message}</div>
                )}
              >
                <Suspense fallback={<div className="loading-spinner"></div>}>
                  <Routes>
                    <Route path={paths.root} element={<Landing />} />
                    <Route path={paths.ai} element={<Ai />} />
                    // ! Redirects
                    {legacyRedirectsData.map(([oldPath, newPath], index) => (
                      <Route
                        key={index}
                        path={oldPath}
                        element={<Navigate to={newPath} replace />}
                      />
                    ))}
                    {externalRedirectsData.map(
                      ([oldPath, externalUrl], index) => (
                        <Route
                          key={index}
                          path={oldPath}
                          element={<ExternalRedirect to={externalUrl} />}
                        />
                      )
                    )}
                    <Route
                      path={"/partners"}
                      element={<Navigate to="/integrations" />}
                    />
                    <Route
                      path="/insights"
                      element={<Navigate to={paths.insightsReporting} />}
                    />
                    <Route
                      path="/integrate/*"
                      element={<Navigate to={paths.integrations} />}
                    />
                    <Route
                      path="/mobile"
                      element={<Navigate to={paths.features} />}
                    />
                    <Route
                      path="/pack"
                      element={<Navigate to={paths.shipping} />}
                    />
                    <Route
                      path="/pick"
                      element={<Navigate to={paths.orderPicking} />}
                    />
                    <Route
                      path="/ship"
                      element={<Navigate to={paths.shipping} />}
                    />
                    {/* Inventory and About */}
                    <Route path={paths.about} element={<About />} />
                    {/* <Route
                      path={paths.inventory}
                      element={<InventoryLanding />}
                    />
                    <Route
                      path={paths.inventoryPricing}
                      element={<InventoryPricing />}
                    /> */}
                    {/* Hardware */}
                    <Route path={paths.hardware} element={<Hardware />} />
                    <Route
                      path={paths.hardwareBarcodeScanners}
                      element={<Hardware category="barcodeScanners" />}
                    />
                    <Route
                      path={paths.hardwareLabelPrinters}
                      element={<Hardware category="labelPrinters" />}
                    />
                    <Route
                      path={paths.hardwareScales}
                      element={<Hardware category="scales" />}
                    />
                    <Route
                      path={paths.hardwareMobileDevices}
                      element={<Hardware category="mobileDevices" />}
                    />
                    <Route
                      path={paths.hardwareNetworkingGear}
                      element={<Hardware category="networkingGear" />}
                    />
                    {/* Academy */}
                    <Route path={paths.academyList} element={<Academy />} />
                    <Route
                      path={paths.academy.benefitsOfInventoryManagementSystems}
                      element={<Academy content={<AcademyBenefits />} />}
                    />
                    <Route
                      path={paths.academy.inventoryManagementForSmallBusiness}
                      element={
                        <Academy content={<AcademyInventoryManagement />} />
                      }
                    />
                    <Route
                      path={paths.academy.whatIsMultichannelInventoryControl}
                      element={
                        <Academy content={<AcademyMultichannelInventory />} />
                      }
                    />
                    <Route
                      path={paths.academy.introductionToInventoryForecasting}
                      element={
                        <Academy content={<AcademyInventoryForecasting />} />
                      }
                    />
                    <Route
                      path={paths.academy.introductionToCycleCounts}
                      element={<Academy content={<AcademyCycleCounts />} />}
                    />
                    <Route
                      path={paths.academy.serializedInventoryManagement}
                      element={
                        <Academy content={<AcademySerializedInventory />} />
                      }
                    />
                    {/* Industries */}
                    {Object.entries(paths.industriesLinks).map(
                      ([key, path]) => (
                        <Route key={key} path={path} element={<Industries />} />
                      )
                    )}
                    {/* Integrations */}
                    <Route path={paths.partners} element={<Integrations />} />
                    {integrationsList.map((company) => {
                      const link = `/integrations/${company.companyName
                        .toLowerCase()
                        .replace(" ", "")}`;
                      return (
                        <Route
                          key={company.companyName}
                          path={link}
                          element={<SingleIntegration data={company} />}
                        />
                      );
                    })}
                    {/* Feature  */}
                    <Route path={paths.features} element={<Features />} />
                    <Route
                      path={paths.inventoryManagement}
                      element={<Features />}
                    />
                    <Route path={paths.orderPicking} element={<Features />} />
                    <Route path={paths.shipping} element={<Features />} />
                    <Route path={paths.batchPicking} element={<Features />} />
                    <Route
                      path={paths.orderManagement}
                      element={<Features />}
                    />
                    <Route path={paths.pointOfSale} element={<Features />} />
                    <Route path={paths.purchaseOrder} element={<Features />} />
                    <Route
                      path={paths.warehouseManagement}
                      element={<Features />}
                    />
                    <Route
                      path={paths.catalogManagement}
                      element={<Features />}
                    />
                    <Route
                      path={paths.insightsReporting}
                      element={<Features />}
                    />
                    {/* Pricing */}
                    <Route path={paths.pricing} element={<PricingTable />} />
                    {/* Other */}
                    <Route path={paths.termsOfUse} element={<TermsOfUse />} />
                    <Route path={paths.sitemap} element={<Sitemap />} />
                    <Route
                      path={paths.privacyPolicy}
                      element={<PrivacyPolicy />}
                    />
                    <Route path={paths.gdpr} element={<Gdpr />} />
                    <Route path={paths.ccpa} element={<Ccpa />} />
                    <Route path={paths.reviews} element={<Reviews />} />
                    <Route path={paths.thanks} element={<Reviews />} />
                    <Route path={paths.connect} element={<Connect />} />
                    <Route path={paths.health411} element={<Health411 />} />
                    <Route path={paths.process} element={<Process />} />
                    <Route
                      path={paths.stitchlabsAlternative}
                      element={<StitchlabAlternative />}
                    />
                    <Route path={paths.faq} element={<FaqPage />} />
                    <Route path={paths.changelog} element={<ChangeLog />} />
                    <Route path={"/loading"} element={<Loading />} />
                    <Route path="/redirecting" element={<Redirecting />} />
                    <Route path={paths.book} element={<Demo />} />
                    <Route path={paths.call} element={<Call />} />
                    <Route path={paths.talk} element={<Talk />} />
                    <Route path={paths.contacts} element={<Contacts />} />
                    <Route path={paths.caseStudies.hot} element={<HoT />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </SkulabsDotCom>
          )}
        </Suspense>
      </ErrorBoundary>
    </SkulabsContext.Provider>
  );
};

export default SkulabsRouter;
