import React, { Suspense } from "react";
import { useMediaQuery } from "react-responsive";

const DesktopMenu = React.lazy(() => import("./DesktopMenu"));
const MobileMenu = React.lazy(() => import("./MobileMenu"));

interface NavMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const isDesktop = useMediaQuery({ minWidth: 961 });

  return (
    <Suspense fallback={<div></div>}>
      {isDesktop ? (
        <DesktopMenu />
      ) : (
        <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      )}
    </Suspense>
  );
};

export default NavMenu;
