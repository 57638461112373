import { Link } from "react-router-dom";

export const AsideCta = () => {
  return (
    <div className="aside-cta">
      <h4>Help Center</h4>
      <p>
        Our experts are ready to help you. We are here to solve your business
        challenges.
      </p>
      <div className="ctas">
        <Link to="/pricing" className="cta">
          Get Started Today
        </Link>
        <a href="/talk" className="cta-secondary">
          Contact with team
        </a>
      </div>
    </div>
  );
};

export default AsideCta;
