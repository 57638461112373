import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation } from "react-router-dom";
import { VideoSection } from "components/Layouts/Integrations";
import SupportSection from "components/Layouts/Support/SupportSection";
import menu from "data/menuData";
import NotFound from "Pages/NotFound";
import featureData from "data/featuresData";
import { Suspense } from "react";
import ReviewsContent from "components/Layouts/Reviews/ReviewsContent";

const Features = () => {
  const menuFeature = menu[0];
  const location = useLocation();

  const page = featureData[location.pathname];
  const videoPreview = "/images/skulapro-video-preview.webp";

  if (!page) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{page.title || "SKULabs Features Overview"}</title>
        <meta
          name="description"
          content={
            page.description ||
            "Learn how SKULabs can help you run your ecommerce business"
          }
        />
      </Helmet>
      <main>
        <section className="features">
          <div className="container">
            <h1>{page.title || "SKULabs Features"}</h1>
            <div className="features-content">
              <aside className="aside">
                <div className="aside-menu">
                  {menuFeature.subMenu?.map((item, index) => (
                    <NavLink
                      to={item.url as string}
                      key={`${item.url}-${index}`}
                    >
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </aside>

              <div className="features-list">
                {page.video && (
                  <VideoSection
                    video={page.video}
                    previewVideoDesc={videoPreview}
                  />
                )}
                {page.content.map((item, index) => (
                  <div key={item.title} className="feature-item">
                    {/* {item.image && (
                      <img
                        src={item.image}
                        alt={item.alt}
                        className="feature-img"
                      />
                    )} */}
                    <div className="feature-info">
                      {item.link ? (
                        <Link to={item.link}>
                          <h2 className="title">{item.title}</h2>
                        </Link>
                      ) : (
                        <h2 className="title">{item.title}</h2>
                      )}
                      <p>{item.description}</p>
                      {item.link && (
                        <Link to={item.link} className="cta">
                          {item.linkTitle || "About Feature"}
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
                <div className="triger-section">
                  <h3>Contact us today to schedule a demo </h3>
                  <p>
                    Discover the features and benefits that helped transform
                    their business and see how it can do the same for yours.
                    From inventory control to fast, accurate fulfillment,
                    SKULabs provides everything you need to grow confidently.
                  </p>
                  <div className="cta-container">
                    <Link to="/demo" className="cta">
                      Get Live Demo
                    </Link>
                    <a href="tel:+1 (800) 243-2986" className="cta-secondary">
                      Call us at:{" "}
                      <span className="accent">+1 (800) 243-2986</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Suspense fallback={<div>Loading reviews...</div>}>
        <div className="container">
          <h2 style={{ textAlign: "center" }}>
            What Our Clients Think about <span className="accent">SKULabs</span>{" "}
          </h2>
        </div>
        <ReviewsContent />
      </Suspense>
      <SupportSection />
    </>
  );
};

export default Features;
