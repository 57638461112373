import React, { useState } from "react";
import { comparePlanData, pricingPlans } from "data/pricingData";

type ModalFeaturesProps = {
  planName: keyof typeof pricingPlans;
  onClose: () => void;
};

const ModalFeatures: React.FC<ModalFeaturesProps> = ({ planName, onClose }) => {
  const planKeys = Object.keys(pricingPlans) as Array<
    keyof typeof pricingPlans
  >;
  const [currentPlanName, setCurrentPlanName] = useState(planName);

  const currentPlan = pricingPlans[currentPlanName];
  const planKey = currentPlan.name.toLowerCase() as keyof typeof pricingPlans;

  const handlePrevPlan = () => {
    const currentIndex = planKeys.indexOf(currentPlanName);
    const prevIndex = (currentIndex - 1 + planKeys.length) % planKeys.length;
    setCurrentPlanName(planKeys[prevIndex]);
  };

  const handleNextPlan = () => {
    const currentIndex = planKeys.indexOf(currentPlanName);
    const nextIndex = (currentIndex + 1) % planKeys.length;
    setCurrentPlanName(planKeys[nextIndex]);
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-features" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="plans-header">
            <div className="col">
              <div className="plan-features-head">
                <p>
                  {currentPlan.name} - <span>{currentPlan.price}</span>
                </p>
                <a
                  href={`https://next.skulabs.com/signup?plan=${currentPlan.code}`}
                  className="cta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Choose this plan
                </a>
              </div>
            </div>
          </div>
          {comparePlanData.map((category, catIndex) => (
            <div key={catIndex} className="feature-category">
              <h3>{category.title}</h3>
              <div className="category-table">
                {category.features.map((feature, featIndex) => (
                  <div className="row" key={featIndex}>
                    <div className="col feature-name">
                      <span>{feature.name}</span>
                    </div>
                    <div className="col">
                      {feature.plans[planKey] ? (
                        <img
                          src="/icons/check.svg"
                          alt="check"
                          width={15}
                          height={11}
                        />
                      ) : (
                        <img
                          src="/icons/dot.svg"
                          alt="dot"
                          width={12}
                          height={12}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="modal-controls">
          <button
            className="modal-prev-button"
            onClick={handlePrevPlan}
          ></button>
          <button className="modal-close-button" onClick={onClose}>
            Close
          </button>
          <button
            className="modal-next-button"
            onClick={handleNextPlan}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default ModalFeatures;
