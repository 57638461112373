export type PlanFeature = {
  icon?: string;
  name: string;
  included?: boolean;
  limit?: number | string;
  additionalCost?: string;
};

export type Plan = {
  name: string;
  price: string;
  description?: string;
  code: string;
  mainFeatures: PlanFeature[];
};

export type PricingPlans = {
  starter: Plan;
  pro: Plan;
  premium: Plan;
};

export const pricingPlans: PricingPlans = {
  starter: {
    name: "Starter",
    price: "$299/mo",
    description: "Everything you need to start shipping and tracking inventory",
    code: "basic2023",
    mainFeatures: [
      {
        icon: "/icons/orders.svg",
        name: "Orders",
        limit: "1,000",
        additionalCost: "+$0.20/ea",
      },
      {
        icon: "/icons/users.svg",
        name: "Users",
        limit: 2,
        additionalCost: "+$75/ea",
      },
      {
        icon: "/icons/sale-channels.svg",
        name: "Sales Channels",
        limit: 2,
        additionalCost: "+$25/ea",
      },
      {
        icon: "/icons/warehouse.svg",
        name: "Warehouses",
        limit: 2,
        additionalCost: "+$50/ea",
      },
      {
        icon: "/icons/sku.svg",
        name: "SKUs",
        limit: "10,000",
        additionalCost: "+$15/1000",
      },
      { icon: "/icons/sessions.svg", name: "Onboarding Sessions", limit: 3 },
      {
        icon: "/icons/api.svg",
        name: "API Calls/Day",
        limit: "1,000 calls/day",
      },
    ],
  },
  pro: {
    name: "Pro",
    price: "$999/mo",
    description:
      "More sales channels, more features, and more room to grow your business",
    code: "pro2023",
    mainFeatures: [
      {
        icon: "/icons/orders.svg",
        name: "Orders",
        limit: "10,000",
        additionalCost: "+$0.10/ea",
      },
      {
        icon: "/icons/users.svg",
        name: "Users",
        limit: 5,
        additionalCost: "+$50/ea",
      },
      {
        icon: "/icons/sale-channels.svg",
        name: "Sales Channels",
        limit: 5,
        additionalCost: "+$25/ea",
      },
      {
        icon: "/icons/warehouse.svg",
        name: "Warehouses",
        limit: 5,
        additionalCost: "+$25/ea",
      },
      {
        icon: "/icons/sku.svg",
        name: "SKUs",
        limit: "50,000",
        additionalCost: "+$10/1000",
      },
      { icon: "/icons/sessions.svg", name: "Onboarding Sessions", limit: 6 },
      {
        icon: "/icons/api.svg",
        name: "API Calls/Day",
        limit: "100,000 calls/day",
      },
    ],
  },
  premium: {
    name: "Premium",
    price: "$1999/mo",
    description:
      "Get started today or speak with sales to negotiate a custom pricing plan.",
    code: "enterprise2023",
    mainFeatures: [
      {
        icon: "/icons/orders.svg",
        name: "Orders",
        limit: "50,000",
        additionalCost: "+$0.05/ea",
      },
      {
        icon: "/icons/users.svg",
        name: "Users",
        limit: 5,
        additionalCost: "+$40/ea",
      },
      {
        icon: "/icons/sale-channels.svg",
        name: "Sales Channels",
        limit: 5,
        additionalCost: "+$20/ea",
      },
      {
        icon: "/icons/warehouse.svg",
        name: "Warehouses",
        limit: 5,
        additionalCost: "+$20/ea",
      },
      {
        icon: "/icons/sku.svg",
        name: "SKUs",
        limit: "200,000",
        additionalCost: "+$10/1000",
      },
      { icon: "/icons/sessions.svg", name: "Onboarding Sessions", limit: 9 },
      {
        icon: "/icons/api.svg",
        name: "API Calls/Day",
        limit: "300,000 calls/day",
      },
    ],
  },
};

export const comparePlanData = [
  {
    title: "Inventory Management",
    features: [
      {
        name: "Stock Sync",
        description:
          "Allows SKULabs to act as your master of inventory and push outbound inventory to your sales channel, overwriting the inventory quantities on your chosen products and sales channels.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Cycle Count",
        description:
          "Cycle counts, sometimes called stocktakes, are small batches of inventory counting tasks for a single location, or a single group of items, within a warehouse.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Stock Transfers",
        description:
          "Transfer orders allow you to relocate inventory between warehouses and locations in an organized fashion.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Deduction",
        description:
          "SKULabs offers multiple ways for your team to efficiently pick, pack, and ship while maintaining accurate inventory by deducting orders. Deduction is required when using SKULabs as a master of inventory to prevent overselling.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "On-Hand Inventory count",
        description:
          "This count represents the total quantity of this item in stock. It includes stock that may be reserved for open orders as well as the remaining stock that is free for purchase.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Kits & Bundles",
        description:
          "Kits, bundles, or multi-packs are listings which your team fulfills by picking one or more individual items. When a listing linked to a kit appears on an order, you will be prompted to pick and ship each of the component items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Listing Sync",
        description:
          "A listing is an offer for sale on a sales channel. You can have multiple listings for each inventory item in SKULabs.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Stock Receive",
        description:
          "The Receive tab allows you to scan incoming item barcodes, type or scan a location, and quickly add that item to your inventory. You can use receiving for incoming returns, distributor purchases, and more.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Stock Deduct",
        description:
          "SKULabs offers multiple ways for your team to efficiently pick, pack, and ship while maintaining accurate inventory by deducting orders. Deduction is required when using SKULabs as a master of inventory to prevent overselling.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Incoming Stock Detail",
        description:
          "This count represents any stock that is on an open Purchase Order. Once the item in the purchase order has been received, this count will be reduced and the on hand count will be increased.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "ABC Mover Analysis",
        description:
          'The mover rating is a ranking of each item in your inventory based on recent sales volume. "A" movers are your top 20% of items sold recently, while "B" items are your next 60%, and "C" items are your bottom 20%.',

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Last Counted Insight",
        description:
          "This indicates when this item was last counted as part of a cycle count. This will be empty if the item has never been included in any cycle counts before.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Days Left",
        description:
          "This indicates the number of days before selling out available inventory for an item.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Item/Listing Tags",
        description:
          "Tags are a simple but flexible way to help better identify, sort, filter, and categorize information throughout SKULabs.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Price/Cost Sync",
        description:
          'SKULabs offers the ability to update your "purchase" prices on many sales channels. This sync is outbound only',

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "User Permissions",
        description:
          "SKULabs offers customizable roles, preferences, and user permissions that can be enabled per user by a manager, or administrator.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Low Stock Alert",
        description:
          "The alert level is a threshold for when you would like to be notified of a low inventory count.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Order Management",
    features: [
      {
        name: "Sales Channel Sync",
        description:
          "Stock sync is an opt-in feature which allows SKULabs to act as your master of inventory and push outbound inventory to your sales channel",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Order Sync",
        description:
          "Imports orders from all connected sales channeles (i.e Shopify, Amazon, etc.)",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Order Status",
        description:
          "Order sync will automatically update order status in certain cases. Each store has it's own settings for what statuses of orders we will pull in and subsequently update under integration settings which vary by provider.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Order Tags",
        description:
          "Tags are a simple but flexible way to help better identify, sort, filter, and categorize information throughout SKULabs.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Channel Status",
        description:
          "Channel statuses provide important information for your fulfillment operation. For instance, it helps you prevent shipping unpaid or pending orders, if you don't fulfill unpaid orders.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Fulfillable Status",
        description:
          "Statuses can be used to indicate an order's fulfillment progress and can be filtered to help streamline your team's process.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Manunal Orders",
        description:
          "Allows you to reserve the inventory and to pick and verify and deduct items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Picking Preference Suggestions",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Customer Order Notification",
        description:
          "Your sales channel likely sends emails on your behalf when orders are marked shipped by SKULabs. When that is not the case, you can enable email notification using your store settings",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Bar Code Scan Verification",
        description:
          "Use barcode scanning throughout SKULabs to make inventory and order management workflows faster, easier, and more accurate. You can use a barcode scanner all throughout the app including receiving, counting, order picking verification, and more.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Item Location Detail",
        description:
          "SKULabs supports location naming within your warehouse to assist with tasks like order picking, stock counting, and receiving/put-away.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Edit Order",
        description:
          "When orders must be edited within SKULabs directly, these are often best saved for quick and simple adjustments prior to shipping, such as changing an order's status, or correcting an address before shipping.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Scan Log History",
        description:
          "All activity executed in SKULabs is recorded and logged giving your team line of sight to all movements within your operation.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Customer History",
        description:
          "Customers are imported from open orders. View past orders by customer.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Returns (RMA Management)",
        description:
          "Most returns are processed in your sales channel, however if this isn't the case SKULabs can help assist your return workflow.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Pick, Pack & Ship",
        description:
          "Easily fulfill items with workflows designed to efficiently pick, pack, and ship inventory directly to customers.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Single Order Picking",
        description:
          "Check orders one at a time allowing teams to verify orders and ship all in one system.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Batch Picking",
        description:
          "This is a more advanced method of checking orders, enabling the user to pick, pack, and ship several orders at once.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Shipping Management",
    features: [
      {
        name: "Carrier Connection (EasyPost)",
        description:
          "Connect all of your carriers saving your team time and money being a single point of integration.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipment Order History",
        description:
          "The Shipments tab allows you to view and manage shipped packages within a given time range. From here, you can calculate rates for a package, create new shipments, or manage your previous shipments.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipping Audit",
        description:
          'To get a stronger grasp on your shipping expenses, run a shipping audit (either individually by clicking the dollar sign next to each shipment or all at once by pressing "Audit”). We suggest running this report daily to ensure that the least expensive and most appropriate shipping methods are being chosen.',

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipping Manifests",
        description: `A manifest is a form documenting all of your day's shipment details for your carrier and is also known as an "end of day" or "SCAN form."`,

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipping Memorization",
        description: `When shippers choose to "memorize" shipments, SKULabs remembers the shipment configuration for the next time an order is shipped with the same contents.`,

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Order Box Management",
        description: `Assign nicknames and locations for packaging materials to help stay organized. Specify default packaging using shipping rules or per-store shipping defaults. Create an item with a SKU matching this format and assign the box to one location per warehouse to track box inventory.`,

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "EasyPost Purchase Management",
        description: `Review your purchases made on EasyPost, easily identify unused labels and submit refund requests. Auto-void service arriving in an upcoming update.`,

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Purchasing",
    features: [
      {
        name: "Replenishment Report",
        description:
          "Stock alerts also drive semi-automated replenishment of hot picking areas, replenishment of your multiple retail store locations from a warehouse or main store, and can be used for placing purchase orders with your suppliers.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Create purchase order",
        description:
          "This allows you to quickly create a new purchase order from one of your stores to a distributor.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Supplier Management",
        description: "Quickly add in all of your suppliers and their items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Order Import",
        description:
          "Import Purchase orders through QuickBooks or manual CSVs.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Scan to Receive Inventory",
        description:
          "Once a purchase order is created, you can click or scan it's barcode on the Purchasing page to open and begin receiving products.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Order Total Outstanding",
        description:
          "Line of site to all purchase orders and their outstanding items and total value.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Print Barcodes",
        description: "Print barcodes as you receive new inventory.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Order Tag Management",
        description:
          "You can add tags to or remove tags from selected purchase orders by toggling the tags in this menu. Select Manage tags to add or edit available tags.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Bulk Inventory Accept",
        description:
          "Accept all inventory 1 item at a time or bulk accept an entire purchase order.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Order Status",
        description:
          "This indicates whether or not the purchase order has begun the process of receiving.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Bulk Status Change",
        description:
          "This will quickly change the processing status for the selected purchase orders to the status of your choice.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "BackOrder Report",
        description:
          "This allows you to quickly see which items in your processing purchase orders are still waiting to be received.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Accounting Integrations",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Automations",
    features: [
      {
        name: "Warehouse Stock Export",
        description:
          "Export SKU, barcode, name, and current stock counts for your catalog items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Low Stock Report",
        description:
          "Export SKU, barcode, name, and current catalog items that have crossed Low Stock Alert Levels.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Picking Performance Export",
        description:
          "You can also view the picking/scanning accuracy of a user.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipments export",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Sync Orders",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Sync All Listings",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Printing",
    features: [
      {
        name: "Barcode Printing",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipping Labels",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Packing Slips",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Picking Slips",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Template Customization",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Imports/Exports",
    features: [
      {
        name: "Inventory Item Details",
        description:
          "Manage thousands of SKUs at once with our easy-to-use CSV import feature.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Item Tags",
        description: "Bulk assign item tags.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Items Stock",
        description: "Bulk assign locations to items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Warehouse Stock",
        description:
          "Export SKU, barcode, name, and current stock counts for your catalog items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Transfer Orders",
        description:
          "Easily move stock to different locations with a bulk CSV upload.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Listings by Channel",
        description:
          "Import listings from problematic or slow sales channels to jump start the SKULabs inventory process. Variants are not supported.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Kits",
        description:
          "Manage thousands of SKUs & Kits at once with our easy-to-use CSV import feature.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Cycle Counts",
        description:
          "Export past and present cycle counts for use in other reporting software.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Inventory Receiving",
        description: "Receive stock for multiple items.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Orders",
        description:
          "Automatically create manual orders using organized and easy CSV files.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Order Tags",
        description: "Bulk add/remove order tags.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Shipments",
        description:
          "Create a row with a copy of the shipment information followed by the SKU and Quantity for each item in the shipment.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Orders",
        description:
          "Export purchase orders from the provided date range/Easily create purchase orders to different suppliers with a bulk CSV upload.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase orders receive",
        description:
          "Select a purchase order to receive via CSV/Select the received date range you would like to export.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Suppliers",
        description:
          "Easily upload suppliers and their addresses with a CSV file/Export your current suppliers information.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Supplier Items",
        description:
          "Easily upload links between suppliers and your items with a CSV file.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Supplier Inventory",
        description:
          "Select a distributor, then upload a CSV file/Export your current suppliers and item pricing.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Purchase Orders received History",
        description:
          "Select the received date range you would like to export..",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Additional Imports/Exports",
    features: [
      {
        name: "Customers",
        description: "Bulk upload customers.",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Listings (Remote Store)",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Walmart Listings",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
    ],
  },
  {
    title: "Support",
    features: [
      {
        name: "Chat",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Phone",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Email",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Ticket",

        plans: {
          starter: true,
          pro: true,
          premium: true,
        },
      },
      {
        name: "Beta Feature Access",
        plans: {
          starter: false,
          pro: false,
          premium: true,
        },
      },
      {
        name: "Customization",
        plans: {
          starter: false,
          pro: false,
          premium: true,
        },
      },
    ],
  },
];

export default pricingPlans;
