import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import FAQItem from "components/Layouts/Faq/FAQItem";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";
import faqPageData from "data/faqData";

const FAQPage: React.FC = () => {
  const [openItems, setOpenItems] = useState<{
    [category: string]: number | null;
  }>({});
  const [activeCategory, setActiveCategory] = useState<string>("");

  const categories = Array.from(
    new Set(faqPageData.map((item) => item.category || "Uncategorized"))
  );

  const faqsByCategory = categories.map((category) => ({
    category,
    items: faqPageData.filter(
      (item) => (item.category || "Uncategorized") === category
    ),
  }));

  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  categories.forEach((category) => {
    if (!categoryRefs.current[category]) {
      categoryRefs.current[category] = null;
    }
  });

  const handleClickItem = (category: string, index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [category]: prev[category] === index ? null : index,
    }));
  };

  const handleScrollToCategory = (category: string) => {
    categoryRefs.current[category]?.scrollIntoView({ behavior: "smooth" });
    setActiveCategory(category);
  };

  return (
    <>
      <Helmet>
        <title>Frequently asked Questions - SKULabs</title>
        <meta
          name="description"
          content="Find answers to your most popular questions and learn how our support can help you with orders, shipping, returns and other inquiries. Explore our solutions for effective customer communication."
        />
      </Helmet>
      <main className="integration">
        <div className="container">
          <h1>How can we help you</h1>
          <p>
            Find answers to your most popular questions and learn how our
            support can help you with orders, shipping, returns and other
            inquiries. Explore our solutions for effective customer
            communication.
          </p>
        </div>
        <div className="faq-container container">
          <div className="faq-navigation">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleScrollToCategory(category)}
                className={`link ${
                  activeCategory === category ? "active" : ""
                }`}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="faq-categories">
            {faqsByCategory.map(({ category, items }) => (
              <div
                key={category}
                id={category}
                className="faq-category"
                ref={(el) => {
                  categoryRefs.current[category] = el;
                }}
              >
                <h3>{category}</h3>
                <div className="faq-list">
                  {items.map((item, index) => (
                    <FAQItem
                      key={index}
                      isOpen={openItems[category] === index}
                      item={item}
                      onClick={() => handleClickItem(category, index)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <SupportSection />
    </>
  );
};

export default FAQPage;
