const supportData = [
  {
    icon: "/images/support/us-shield.svg",
    title: "United State Based",
    descriptiom:
      "Never outsourced, our support team operates 100% in-house from our South Florida offices.",
  },
  {
    icon: "/images/support/quick-response-time.svg",
    title: "Quick Response Time",
    descriptiom:
      "Most emails and tickets are answered within 4 hours, and our phone support team is available 7 days a week.",
  },
  {
    icon: "/images/support/chat.svg",
    title: "Tickets and Phone",
    descriptiom:
      "Contact us via our support ticket system or our toll-free phone line. Call us +1 (800) 243-2986",
  },
];

export default supportData;
