import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

interface Review {
  avatar: string;
  description: string;
  logo: string;
  name?: string;
  position?: string;
  caseStudyLink?: string;
}

interface ReviewsSliderProps {
  reviewsData: Review[];
}

const ReviewsSlider: React.FC<ReviewsSliderProps> = ({ reviewsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const sliderRef = useRef<HTMLDivElement>(null);
  const startX = useRef(0);
  const endX = useRef(0);

  const navigationArrow = "/icons/slider-arrow.svg";
  const defaultAvatar = "/icons/default-avatar.svg";
  const linkArrow = "/icons/links-arrow.svg";

  const goToPrevious = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? reviewsData.length - 1 : prevIndex - 1
      );
      setIsAnimating(false);
    }, 500);
  };

  const goToNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
      );
      setIsAnimating(false);
    }, 500);
  };

  const handleTouchStart = (e: TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: TouchEvent) => {
    endX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (startX.current - endX.current > 50) {
      goToNext();
    } else if (startX.current - endX.current < -50) {
      goToPrevious();
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("touchstart", handleTouchStart);
      slider.addEventListener("touchmove", handleTouchMove);
      slider.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (slider) {
        slider.removeEventListener("touchstart", handleTouchStart);
        slider.removeEventListener("touchmove", handleTouchMove);
        slider.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  const getItemClassName = () => {
    return `testimonial-item ${isAnimating ? "animate" : ""}`;
  };

  const currentReview = reviewsData[currentIndex];

  return (
    <div className="testimonial-slider" ref={sliderRef}>
      <div className={getItemClassName()}>
        <div className="reviewer-avatar">
          <img
            src={currentReview.avatar || defaultAvatar}
            alt={currentReview.name}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = defaultAvatar;
            }}
          />
        </div>
        <div className="reviewer-container">
          <div className="slider-controls">
            <div className="arrow prev" onClick={goToPrevious}>
              <img
                src={navigationArrow}
                alt="Previous Arrow"
                width={40}
                height={40}
              />
            </div>
            <div className="arrow next" onClick={goToNext}>
              <img
                src={navigationArrow}
                alt="Next Arrow"
                width={40}
                height={40}
              />
            </div>
          </div>
          <div className="reviewer-content">
            <img
              src={currentReview.logo}
              alt={currentReview.name}
              className="reviewer-logo"
            />
            <p className="reviewer-description">{currentReview.description}</p>
            <div
              className={
                currentReview.caseStudyLink
                  ? "reviewer-info"
                  : "reviewer-info center"
              }
            >
              {currentReview.name && (
                <div className="review-autor">
                  <p className="reviewer-position">{currentReview.position}</p>
                  <p className="reviewer-name title">
                    <strong>{currentReview.name}</strong>
                  </p>
                </div>
              )}
              {currentReview.caseStudyLink && (
                <Link to={currentReview.caseStudyLink} className="link-more">
                  View Case Study{" "}
                  <img
                    className="arrow"
                    src={linkArrow}
                    alt="Arrow"
                    width={24}
                    height={24}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSlider;
